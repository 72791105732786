import React from 'react';

function Overview (props) {
	return (
		<div className='overview fade-in'>
		    			{props.langageSwitch()}
		    			<div className='flex justify-start ma3'>
		    				<div className='flex flex-row items-center'>
		    					<div 
									className='bg-black yellow dib br3 pa3 mt2 mb2 bw2 shadow-5 o-80'
									onClick={()=>props.showSection('home')}>
									<img className='o-100 w4' src={props.logo} alt='Sinkiat Thani Hotel'/>
						    	</div>
				    			<div className='flex justify-around'>
					    			{props.contentSwitch()}
				    			</div>
		    				</div>
		    			</div>
		    			<div className='fixed bottom-1 w-100'>
			    			<div className='w-100 h-60 bg-black white o-80 dib tc pa3 mt2 mb2 bw2 shadow-5 '>
				    				<div className='f3 yellow tl'>
			    					{props.language === 'TH' ? 'ภาพรวม' : props.language === 'CN' ? '概观' : props.language === 'ZN' ? '概觀' : props.language === 'JP' ? '概要' : 'Overview'}
			    					</div>
				    				{
				    					props.language === 'TH' ? 
					    					`โรงแรมสินเกียรติธานี ยินดีต้อนรับทุกท่านด้วยความอบอุ่น ด้วยทำเลที่ตั้งใจกลางเมืองสตูล 
					    					ทำให้ท่านสามารถเดินทางท่องเที่ยวในตัวเมืองได้ด้วยความสะดวกสบาย 
					    					โรงแรมของเราอยู่ห่างจากท่าเรือตำมะลังเพียง 15 นาที สามารถเดินทางไปกลับเกาะลังกาวีได้โดยง่าย
					    					มีลิฟท์โดยสารบริการ,ไวไฟฟรี, รถเข็นสามารถผ่านได้ทุกบริเวณโรงแรม
					    					ยินดีบริการแนะนำสถานที่ท่องเที่ยวแก่ท่าน พร้อมด้วยสิ่งอำนวยความสะดวกระดับมาตรฐาน
					    					มีให้ท่านเลือกห้องพักในทุกระดับราคาที่ท่านพอใจ
					    				`:
				    					props.language === 'JP' ? `
				    						「シンキァッタニホテル」　はサトゥン町中央におる。
				    						マレーシア境の近くであり、ランカウイ島に乗り換えることが便利になる。
				    						裏は部屋から丘又は河原の眺めが見たり、前に色々なお食べ物が探すことができる。
				    						ホテル全体でのWIFIアクセス。エレベーター装備、全エリア車椅子でアクセス可能。 
				    						サトゥンは静かな町であっても、美しい自然であり、野外活動が様々にある。
				    						ご都合に合ったら、是非いらっしゃりください。
				    					`　:
				    					props.language === 'CN' ? `
				    						新建兴特尼酒店位于泰国沙墩府中心。挨着马来西亚，很方便得去兰卡威岛。 酒店周围有许多地道食品， 便得去品尝。
				    						免费无线上网.電梯装备很方便。轮椅通道。
				    						沙墩市是-个又平静，又自然丰富多彩的城市。
				    					` :
				    					props.language === 'ZN' ? `
				    						新建興特尼酒位於泰國沙頓府中心。挨著馬來西亞，很方便得去蘭卡威島。酒店周圍有許多地道食品， 便得去品嚐。
				    						免費無線上網。電梯裝備很方便。 輪椅通道。
				    						沙頓市是一個又平靜， 又自然豐富多彩的城市。
				    					`
				    					: `Sinkiat Thani Hotel is situated in the downtown of Satun, close to Malaysian border and easily transfer to Malaysia's Langawi island. 
				    					convenient for sightseeing the area and easy to travel to many islands which lie minutes off the coast in the Andaman Sea. 
				    					This hotel is located in the centre of the town, convenient to shop and finding local delicacies. 
				    					We offer free WIFI. Our building equiped with elevator and all-area wheelchair accessible.
				    					`
				    				}
				    		</div>
			    		</div>
				    </div>
	);
}

export default Overview;