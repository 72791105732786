import React, { useState, useRef } from "react";
import "./Main.css";
import logo from "./img/logo.png";
import Overview from "./section/overview.js";
import Room from "./section/Room.js";
import Activity from "./section/Attraction.js";
import Contact from "./section/Contact.js";
import Gallery from "./section/Gallery.js";
import Booknow from "./section/Booknow.js";
import Modal from "./Modal";
import shalogo from "./img/shalogo.png";

function Main(props) {
  const [language, setLanguage] = useState("EN");
  const [homeSection, setHomeSection] = useState("welcome flex flex-column w3-animate-opacity");
  const [section, setSection] = useState("home");
  const [show, setShow] = useState(false);
  const [modalMarkup] = useState(`
                   <div id='printContents' className='f7' style="height:100%">
                   <img src=${shalogo} alt='sha' width='30%' height='30%' />
                   	<div>
                   		โรงแรมสินเกียรติธานี ผ่านการรับรองมาตรฐานความปลอดภัยด้านสุขอนามัย
                   	</div>
                   	<div>
                   		Sinkiat Thani Hotel is approved by Amazing Thailand Safety & Health Administration (SHA)
                   	</div>
                   	<div className='f1'><b>รับสิทธิ์ส่วนลดค่าที่พักในโครงการ "เราที่ยวด้วยกัน" ตั้งแต่วันนี้ - 30 เมษายน 2566</b></div>
                   </div>`);

  const node = useRef();

  const showSection = (section) => {
    setShow(false);
    setHomeSection("dn");
    setSection("home");
    if (section === "home") {
      setHomeSection("welcome flex flex-column w3-animate-opacity");
      setShow(true);
    } else if (section === "overview") {
      setSection("overview");
    } else if (section === "room") {
      setSection("room");
    } else if (section === "gallery") {
      setSection("gallery");
    } else if (section === "attraction") {
      setSection("attraction");
    } else if (section === "contact") {
      setSection("contact");
    } else if (section === "booknow") {
      setSection("booknow");
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const createMarkup = () => {
    return { __html: modalMarkup };
  };

  const langageSwitch = () => {
    return (
      <div className="flex justify-end">
        <div className={`${language === "EN" ? "b" : "grow"} black dib tc dib br3 pa3 ma2 bw2 o-80`} onClick={() => setLanguage("EN")}>
          English
        </div>
        <div className={`${language === "TH" ? "b" : "grow"} black dib tc dib br3 pa3 ma2 bw2 o-80`} onClick={() => setLanguage("TH")}>
          ภาษาไทย
        </div>
        <div className={`${language === "CN" ? "b" : "grow"} black dib tc dib br3 pa3 ma2 bw2 o-80`} onClick={() => setLanguage("CN")}>
          简体字
        </div>
        <div className={`${language === "ZN" ? "b" : "grow"} black dib tc dib br3 pa3 ma2 bw2 o-80`} onClick={() => setLanguage("ZN")}>
          繁體字
        </div>
        <div className={`${language === "JP" ? "b" : "grow"} black dib tc dib br3 pa3 ma2 bw2 o-80`} onClick={() => setLanguage("JP")}>
          日本語
        </div>
      </div>
    );
  };

  const contentSwitch = (selected) => {
    return (
      <div>
        <div
          className={`${section === "overview" ? "bg-black yellow" : "bg-yellow black grow"} dib tc dib br3 pa2 ma2 bw2 shadow-5 o-80`}
          onClick={() => showSection("overview")}
        >
          {language === "TH" ? "ภาพรวม" : language === "CN" ? "概观" : language === "ZN" ? "概觀" : language === "JP" ? "概要" : "Overview"}
        </div>
        <div
          className={`${section === "room" ? "bg-black yellow" : "bg-yellow black grow"} dib tc dib br3 pa2 ma2 bw2 shadow-5 o-80`}
          onClick={() => showSection("room")}
        >
          {language === "TH" ? "ห้องพัก" : language === "CN" ? "客房" : language === "ZN" ? "客房" : language === "JP" ? "ルーム" : "Rooms"}
        </div>
        <div
          className={`${section === "gallery" ? "bg-black yellow" : "bg-yellow black grow"} dib tc dib br3 pa2 ma2 bw2 shadow-5 o-80`}
          onClick={() => showSection("gallery")}
        >
          {language === "TH" ? "แกลอรี่" : language === "CN" ? "画廊" : language === "ZN" ? "畫廊" : language === "JP" ? "ギャラリー" : "Gallery"}
        </div>
        {/*<div 
				    					    		className={`${section === 'attraction' ? 'bg-black yellow' : 'bg-yellow black grow'} dib tc dib br3 pa2 ma2 bw2 shadow-5 o-80`}
				    					    		onClick={()=>showSection('attraction')}>
				    					    		{language === 'TH' ? 'จุดท่องเที่ยว' : language === 'CN' ? '景点' : language === 'ZN' ? '景點' : language === 'JP' ? 'アトラクション' : 'Attraction'}
				    					    	</div>*/}
        <div
          className={`${section === "contact" ? "bg-black yellow" : "bg-yellow black grow"} dib tc dib br3 pa2 ma2 bw2 shadow-5 o-80`}
          onClick={() => showSection("contact")}
        >
          {language === "TH" ? "ติดต่อ" : language === "CN" ? "联系" : language === "ZN" ? "聯繫" : language === "JP" ? "連絡" : "Contact"}
        </div>
        <a className={`bg-yellow black grow dib tc dib br3 pa2 ma2 bw2 shadow-5 o-80 no-underline`} href="/cafe">
          {language === "TH" ? "คาเฟ่" : language === "CN" ? "咖啡店" : language === "ZN" ? "咖啡店" : language === "JP" ? "カフェ" : "Café"}
        </a>
        {/*<div 
				    					    		className={`${section === 'booknow' ? 'bg-black yellow' : 'bg-yellow black grow'} dib tc dib br3 pa2 ma2 bw2 shadow-5 o-80`}
				    					    		onClick={()=>showSection('booknow')}>
				    					    		{language === 'TH' ? 'จองห้องพัก' : language === 'CN' ? '订房' : language === 'ZN' ? '訂房' : language === 'JP' ? '予約' : 'Book Now'}
				    					    	</div>*/}
      </div>
    );
  };

  return (
    <div>
      <Modal ref={node} show={show} handleClose={() => closeModal()} buttonText='รับสิทธิ์ "เราเที่ยวด้วยกัน"'>
        <div dangerouslySetInnerHTML={createMarkup()} />
      </Modal>
      <div className={homeSection}>
        {langageSwitch()}
        <div className="items-center">
          <a className="no-underline bg-black yellow tc dib br3 pa3 mt2 mb2 bw2 shadow-5 o-80" href="https://www.sinkiathotel.com">
            <img className="o-100 w5" src={logo} alt="Sinkiat Thani Hotel" />
          </a>
          {contentSwitch()}
        </div>
      </div>
      {section === "overview" ? (
        <Overview contentSwitch={contentSwitch} langageSwitch={langageSwitch} showSection={showSection} logo={logo} language={language} />
      ) : section === "room" ? (
        <Room contentSwitch={contentSwitch} langageSwitch={langageSwitch} showSection={showSection} logo={logo} language={language} />
      ) : section === "gallery" ? (
        <Gallery contentSwitch={contentSwitch} langageSwitch={langageSwitch} showSection={showSection} logo={logo} language={language} />
      ) : section === "attraction" ? (
        <Activity contentSwitch={contentSwitch} langageSwitch={langageSwitch} showSection={showSection} logo={logo} language={language} />
      ) : section === "contact" ? (
        <Contact contentSwitch={contentSwitch} langageSwitch={langageSwitch} showSection={showSection} logo={logo} language={language} />
      ) : section === "booknow" ? (
        <Booknow contentSwitch={contentSwitch} langageSwitch={langageSwitch} showSection={showSection} logo={logo} language={language} />
      ) : (
        ""
      )}
    </div>
  );
}

export default Main;
