import React from "react";
import "./App.css";
import Main from "./Main.js";

function App() {
  return (
    <div className="App">
      <Main />
      <div className="footer tc flex items-end w-100 justify-center">
        <div className="tc">
          All Right Reserved © {new Date().getFullYear()}. Sinkiat Thani Hotel™. <a href="http://www.sinkiathotel.com">www.sinkiathotel.com</a>,{" "}
          <a href="tel:+66074-711-336">Tel: +66 074-711336</a>, Email:{" "}
          <a href="mailto:contact@sinkiathotel.com?cc=sinkiatfront@gmail.com&bcc=reservesinkiat@gmail.com&subject=Inquiryสอบถามข้อมูล">
            contact@sinkiathotel.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
