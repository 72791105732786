import React from 'react';
import Grid from 'react-grid-gallery';

import doublebed2 from '../img/doublebed2.jpg';
import doublebed2_s from '../img/doublebed2_s.jpg';
import doublebed3 from '../img/doublebed3.jpg';
import doublebed3_s from '../img/doublebed3_s.jpg';
import twinbed2 from '../img/twinbed2.jpg';
import twinbed2_s from '../img/twinbed2_s.jpg';
import twinbed from '../img/twinbed.jpg';
import twinbed_s from '../img/twinbed_s.jpg';
import backentrance from '../img/backentrance.jpg';
import backentrance_s from '../img/backentrance_s.jpg';
import elevator from '../img/elevator.jpg';
import elevator_s from '../img/elevator_s.jpg';
import diner from '../img/diner.jpg';
import diner_s from '../img/diner_s.jpg';
import hallway from '../img/hallway.jpg';
import hallway_s from '../img/hallway_s.jpg';
import frontentrance from '../img/frontentrance.jpg';
import frontentrance_s from '../img/frontentrance_s.jpg';
import frontdesk from '../img/frontdesk.jpg';
import frontdesk_s from '../img/frontdesk_s.jpg';
import bicycle from '../img/bicycle.jpg';
import bicycle_s from '../img/bicycle_s.jpg';
import shop from '../img/shop.jpg';
import shop_s from '../img/shop_s.jpg';
import cafe from '../img/cafe.jpg';
import cafe_s from '../img/cafe_s.jpg';
import breakfast from '../img/breakfast.jpg';
import breakfast_s from '../img/breakfast_s.jpg';
import localfood from '../img/localfood.jpg';
import localfood_s from '../img/localfood_s.jpg';

function Gallery(props) {
	const IMAGES =
		[{
		        src: doublebed2,
		        thumbnail: doublebed2_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ห้องมาตรฐาน เตียงเดี่ยว" 
			        : props.language === 'JP' ? "標準室　ー　ダーブルベッド" 
			        : props.language === 'CN' ? "标准房 - 双人间" 
			        : props.language === 'ZN' ? "標準房　ー　雙人間" 
		        	: "Standard Room - Double Bed"
		},
		{
		        src: doublebed3,
		        thumbnail: doublebed3_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ห้องมาตรฐาน เตียงเดี่ยว" 
			        : props.language === 'JP' ? "標準室　ー　ダーブルベッド" 
			        : props.language === 'CN' ? "标准房 - 双人间" 
			        : props.language === 'ZN' ? "標準房　ー　雙人間" 
		        	: "Standard Room - Double Bed"
		},
		{
		        src: twinbed2,
		        thumbnail: twinbed2_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ห้องมาตรฐาน เตียงแฝด" 
			        : props.language === 'JP' ? "標準室　ー　ツゥンズベッド" 
			        : props.language === 'CN' ? "标准房 - 对床 （双床）" 
			        : props.language === 'ZN' ? "標準房　ー　對床 （雙床）" 
		        	: "Standard Room - Twins Bed"
		},
		{
		        src: twinbed,
		        thumbnail: twinbed_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ห้องมาตรฐาน เตียงแฝด" 
			        : props.language === 'JP' ? "標準室　ー　ツゥンズベッド" 
			        : props.language === 'CN' ? "标准房 - 对床 （双床）" 
			        : props.language === 'ZN' ? "標準房　ー　對床 （雙床）" 
		        	: "Standard Room - Twins Bed"
		},
		{
		        src: elevator,
		        thumbnail: elevator_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ลิฟท์โดยสาร" 
			        : props.language === 'JP' ? "エレベーター" 
			        : props.language === 'CN' ? "电梯" 
			        : props.language === 'ZN' ? "電梯" 
		        	: "Elevator"
		},
		{
		        src: diner,
		        thumbnail: diner_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ห้องอาหาร" 
			        : props.language === 'JP' ? "食堂" 
			        : props.language === 'CN' ? "食堂" 
			        : props.language === 'ZN' ? "食堂" 
		        	: "Diner"
		},
		{
		        src: breakfast,
		        thumbnail: breakfast_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "อาหารเช้า" 
			        : props.language === 'JP' ? "朝ごはん" 
			        : props.language === 'CN' ? "早餐" 
			        : props.language === 'ZN' ? "早餐" 
		        	: "Breakfast"
		},
		{
		        src: hallway,
		        thumbnail: hallway_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "โถงทางเข้า" 
			        : props.language === 'JP' ? "廊下" 
			        : props.language === 'CN' ? "走廊" 
			        : props.language === 'ZN' ? "走廊" 
		        	: "Hallway"
		},
		{
		        src: frontentrance,
		        thumbnail: frontentrance_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ด้านหน้าตึก" 
			        : props.language === 'JP' ? "表門" 
			        : props.language === 'CN' ? "前门" 
			        : props.language === 'ZN' ? "前門" 
		        	: "Front Entrance"
		},
		{
		        src: frontdesk,
		        thumbnail: frontdesk_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "แผนกต้อนรับ" 
			        : props.language === 'JP' ? "受付" 
			        : props.language === 'CN' ? "总台" 
			        : props.language === 'ZN' ? "總台" 
		        	: "Front Desk"
		},
		{
		        src: cafe,
		        thumbnail: cafe_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ร้านกาแฟ" 
			        : props.language === 'JP' ? "カフェ" 
			        : props.language === 'CN' ? "咖啡店" 
			        : props.language === 'ZN' ? "咖啡店" 
		        	: "Coffee Shop"
		},
		{
		        src: bicycle,
		        thumbnail: bicycle_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "จักรยานให้เช่า" 
			        : props.language === 'JP' ? "レンタバイク" 
			        : props.language === 'CN' ? "租自行车" 
			        : props.language === 'ZN' ? "組自行車" 
		        	: "Guest Rental Bicycle"
		},
		{
		        src: shop,
		        thumbnail: shop_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ร้านขายของที่ระลึก" 
			        : props.language === 'JP' ? "ショップ" 
			        : props.language === 'CN' ? "纪念品店" 
			        : props.language === 'ZN' ? "紀念品點" 
		        	: "Souvenir Shop"
		},
		{
		        src: localfood,
		        thumbnail: localfood_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "อาหารท้องถิ่น" 
			        : props.language === 'JP' ? "現地食品" 
			        : props.language === 'CN' ? "当地食品" 
			        : props.language === 'ZN' ? "當地食物" 
		        	: "Local Foods"
		},
		{
		        src: backentrance,
		        thumbnail: backentrance_s,
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ล็อบบี้ ทางเข้าด้านหลัง" 
			        : props.language === 'JP' ? "ロビーの裏門" 
			        : props.language === 'CN' ? "大厅 - 后门" 
			        : props.language === 'ZN' ? "大廳　ー　後門" 
		        	: "Main Floor - Back Entrance"
		}]

	return (
		<div className='gallery fade-in'>
		    			{props.langageSwitch()}
		    			<div className='flex justify-start ma3'>
		    				<div className='flex flex-row items-center'>
		    					<div 
									className='bg-black yellow dib br3 pa3 mt2 mb2 bw2 shadow-5 o-80'
									onClick={()=>props.showSection('home')}>
									<img className='o-100 w4' src={props.logo} alt='Sinkiat Thani Hotel'/>
						    	</div>
				    			<div className='flex justify-around'>
					    			{props.contentSwitch()}
				    			</div>
		    				</div>
		    			</div>
		    			<div className='fixed bottom-1 w-100'>
			    			<div className='w-100 h-60 bg-black white o-80 dib tc pa3 mt2 mb2 bw2 shadow-5 '>
				    			<div className='f3 yellow tl'>
			    					{props.language === 'TH' ? 'แกลอรี่' : props.language === 'CN' ? '画廊' : props.language === 'ZN' ? '畫廊' : props.language === 'JP' ? 'ギャラリー' : 'Gallery'}
			    				</div>
				    			<div>	
				    				<Grid 
				    					images={IMAGES}
				    					rowHeight={70}
				    					maxRow={1}
				    					enableImageSelection={false}
				    				/>
								</div>
				    		</div>
			    		</div>
				    </div>
	);
}

export default Gallery;